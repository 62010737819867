import axios from 'axios';

const Login = (data) => axios.post('/auth/login',data);
// 登录详情
const getLoginInfo=(data)=>axios.get('/auth/getLoginInfo',data);

//登出
const LoginOut=()=>axios.get('/auth/logout');

export default {
  Login,
  LoginOut,
  getLoginInfo,

}
