<template>
  <div class="login" @keyup.enter.stop="submit()">
    <div class="picbox">
      <div class="diqiu">
        <img src="../assets/images/login/login3.png" /><img class="img" src="../assets/images/login/login2.png" />
      </div>
      <div class="bottom"><img src="../assets/images/login/login1.png" /></div>
    </div>
    <div class="loginBox">
      <div class="logo">
        <div class="img"><img src="../assets/images/login/logo1.png" /></div>
        <div class="text">
          综合能源智慧管理平台
          <p>
            让智慧点亮生活
            <span class="mode">管理端演示系统</span>
          </p>
        </div>
        <div class="icon">
          <i v-for="i in 22" :key="i"></i>
        </div>
      </div>
      <div class="form">
        <p>
          <img src="../assets/images/login/login_user.png" alt="" /><input type="text" v-model="username" />
        </p>
        <p>
          <img src="../assets/images/login/login_pwd.png" alt="" /><input type="password" v-model="password" />
        </p>

        <p>
          <button @click="submit()">登 录</button>
        </p>
      </div>
    </div>
    <div class="footer">
      <a style="color: #fff" href="http://beian.miit.gov.cn" target="_blank">蜀ICP备17018785号-2</a> Copyright &copy;2020<span class="spad"></span>德阳指尖秘码科技有限公司<span class="spad"></span>联系电话：19981032108

    </div>
  </div>
</template>

<script>
import api from '../apis/login'
export default {
  data() {
    return {
      ssh: false,
      sshObj: {
        time: '',
        ssh: false,
      },
      username: '',
      password: '',
    }
  },
  mounted() {},

  methods: {
    //用户登录
    submit() {
      if (this.username == '') {
        return this.$message.warning('用户名不能为空！')
      } else if (this.password == '') {
        return this.$message.warning('密码不能为空！')
      }

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api
        .Login({ username: this.username, password: this.password })
        .then((res) => {
          loading.close()
          if (res.code === 10000) {
            localStorage.setItem('token', res.data.access_token)
            api.getLoginInfo().then((res)=>{
              if (res.code === 10000) {

                this.$message.success('登录成功')
                localStorage.setItem('deptType', res.data.deptType)
                localStorage.setItem('companyStruct', res.data.companyStruct)
                localStorage.setItem('username', res.data.username)
                localStorage.setItem('userId', res.data.userId)
                localStorage.setItem('region', JSON.stringify(res.data.region))
                localStorage.setItem('plat', JSON.stringify(res.data.plat))
                localStorage.setItem('regionCode',res.data.regionCode)

                localStorage.removeItem('tabCache')
                this.$router.replace('/index')
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error(res.message)
            this.password = ''
          }
        })
    },
  },
}
</script>

<style lang="less">
.login {
  height: 100%;
  position: relative;
  overflow: hidden;
  background: url('../assets/images/login/login_bg.jpg') no-repeat center;
  background-size: 100% 100%;
  .picbox {
    width: 70rem;
    top: 10%;
    overflow: hidden;
    position: absolute;
    left: 20%;
    animation: ii 8s infinite linear 0s;
    .diqiu {
      position: relative;
      z-index: 99;
      margin-bottom: 200px;
      .img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
    .bottom {
      position: absolute; width: 100%;
      bottom: 0px;
      z-index: 22;
    }
  }
  .loginBox {
    background: url('../assets/images/login/loginbg.png') no-repeat center;
    background-size: 100% 100%;
    width: 38rem;
    height: 32rem;
    z-index: 999;
    position: absolute;
    top: 28%;
    right: 25%;
    .logo {
      position: relative;
      height: 11rem;
      padding: 1.4rem;
      padding-top: 2.8rem;
      .img {
        display: inline-block;
        width: 4.4rem;
        height: 5rem;
        vertical-align: middle;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        display: inline-block;
        width: 28rem;
        margin-left: 1rem;
        color: #00ccff;
        vertical-align: middle;
        font-size: 2.4rem;
        p {
          font-family: 'ywsf';
          color: #24ffb1;
          font-size: 1.5rem;
          margin-top: .6rem;
          line-height: 25px;
        }
        .mode {
          background: #1c44a9;
          position: relative;
          top: -2px;
          font-size: 1rem;
          font-family: Arial, Helvetica, sans-serif;
          color: #fff;
          padding: 5px 6px 4px;
          border-radius: 4px;
          margin-left: 10px;
        }
      }
      .icon {
        position: absolute;
        width: 100%;
        height: 1rem;
        bottom: 0px;
        overflow: hidden;
        animation: aa 6s infinite linear 0s;
        i {
          display: inline-block;
          width: 1.6rem;
          height: 1rem;
          background: url('../assets/images/z1.png') top no-repeat; background-size: 100% 100%;
        }
        i:nth-child(3) {
          opacity: 1;
        }
        i:nth-child(4) {
          opacity: 0.95;
        }
        i:nth-child(5) {
          opacity: 0.9;
        }
        i:nth-child(6) {
          opacity: 0.85;
        }
        i:nth-child(7) {
          opacity: 0.8;
        }
        i:nth-child(8) {
          opacity: 0.75;
        }
        i:nth-child(9) {
          opacity: 0.7;
        }
        i:nth-child(10) {
          opacity: 0.65;
        }
        i:nth-child(11) {
          opacity: 0.6;
        }
        i:nth-child(12) {
          opacity: 0.55;
        }
        i:nth-child(13) {
          opacity: 0.5;
        }
        i:nth-child(14) {
          opacity: 0.45;
        }
        i:nth-child(15) {
          opacity: 0.4;
        }
        i:nth-child(16) {
          opacity: 0.35;
        }
        i:nth-child(17) {
          opacity: 0.3;
        }
        i:nth-child(18) {
          opacity: 0.25;
        }
        i:nth-child(19) {
          opacity: 0.2;
        }
        i:nth-child(20) {
          opacity: 0.15;
        }
        i:nth-child(21) {
          opacity: 0.1;
        }
        i:nth-child(22) {
          opacity: 0.05;
        }
      }
    }
    & > div.form {
      padding: 1rem 1.4rem 0px;
      & > p {
        height: 4rem;
        display: flex;
        flex-direction: row;
        margin: 1.6rem 0;
        img {
          float: left;
          width: 4.5rem;
          height: 4rem;
        }
        input {
          height: 4rem;
          flex: 1;
          background: transparent;
          border: 1px solid #1c44a9;
          color: #fff;
          font-size: 1.2rem;
          text-indent: 1rem;
          border-radius: 0 4px 4px 0;
        }
        button {
          width: 100%;
          height: 4rem;
          background: #1c44a9;
          border-radius: 4px;
          border: 0;
          color: #fff;
          font-size: 1.4rem;
          cursor: pointer;
          margin: 1rem auto 0;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
@keyframes ii {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 60px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@keyframes aa {
  0% {
    width: 5%;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 5%;
  }
}
.footer {
  position: absolute;
  width: 100%;
  padding: 1rem;
  text-align: center;
  color: #fff;
  font-size: .8rem;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0.6;
  bottom: 0px;
}
.footer .spad {
  width: 10px;
  display: inline-block;
}
</style>